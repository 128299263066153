import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 10px;
  gap: 10px;

  &:disabled {
   background-color: dimgrey;
   color: linen;
   opacity: 0.2;
  }
`;

export { Form };
