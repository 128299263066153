import * as S from './input-select.styled';
import { DIRECTIONS, UserType } from '../../../const';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { getUser } from '../../../store/user-process/user-process';

// type TSelectFunctionProps = {onLinkClick: () => void};

// export default function City({city, onLinkClick}:CityProps): JSX.Element {

const InputSelect = ({...props}) => {
const user = useAppSelector(getUser);
  return (
    <S.StyledSelectDiv {...props} >
      <button className="custom-select__button" type="button" aria-label="Выберите направление">
        <span className="custom-select__placeholder">Направление</span>
        <span className="custom-select__text"></span>
        <span className="custom-select__icon"></span>
      </button>
      <ul className="custom-select__list" role="listbox">
        {
          user?.type === UserType.Admin ? DIRECTIONS.map((direction, index) =>
          <li key={direction} className="custom-select__item custom-select__item--mod" tabIndex={0} data-select-value={index} aria-selected="false" role="option">{direction}</li>) :
          <li className="custom-select__item custom-select__item--mod" tabIndex={0} data-select-value={0} aria-selected="true" role="option">{user?.direction }</li>
        }
      </ul>
    </S.StyledSelectDiv>
  );
}

export {
  InputSelect
};
