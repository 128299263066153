import styled, { css } from 'styled-components';
import { ReactComponent as arrowDown } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as outHeader } from '../../../assets/icons/out.svg';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  min-width: 190px;
  margin: 0;
  padding-top: 19px;
  padding-right: 47px;
  padding-bottom: 17px;
  padding-left: 47px;

  font-size: ${({ theme }) => theme.font.black};
  line-height: 20px;
  letter-spacing: 0.03em;
  font-size: ${({ theme }) => theme.font.small};
  font-weight: 700;
  text-transform: uppercase;

  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.lightSeaGreen};
  border: none;
  cursor: pointer;
  white-space: nowrap;

  transition: background-color 0.3s;

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.color.chambray};
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.color.chambrayLight};
    outline-offset: 2px;
  }

  &:active:not(:disabled) {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.2;
    cursor: wait;
  }
`;

const ButtonLockForm = styled(Button)`
  padding-top: 14px;
  padding-right: 37px;
  padding-bottom: 12px;
  padding-left: 37px;

  .is-disabled & {
    background-color: ${({ theme }) => theme.color.red};
  }
`;

const ButtonSmall = styled(Button)`
  padding-top: 14px;
  padding-right: 37px;
  padding-bottom: 12px;
  padding-left: 37px;
  background-color: ${({ theme }) => theme.color.chambrayLight};

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.color.blueCharcoal};
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.color.chambrayLight};
    outline-offset: 2px;
  }
`;

const ButtonSmallGreen = styled(ButtonSmall)`
  background-color: ${({ theme }) => theme.color.lightSeaGreen};

  &:focus,
  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.color.chambray};
  }
`;

const ButtonIcon = styled(ButtonSmall)`
  min-width: auto;
  padding: 10px;
  background-color: ${({ theme }) => theme.color.chambrayLight};

  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.color.nepal};
  }

  .is-active & {
    transform: rotate(0deg);
  }
`;

const ButtonOut = styled(Button)`
  min-width: auto;
  padding: 0;
  width: 30px;
  height: 25px;
  background-color: transparent;
  background-color: ${({ theme }) => theme.color.red};
  border: none;
  margin-right: 10px;
  color: ${({ theme }) => theme.color.red};
  &:hover {
    fill: ${({ theme }) => theme.color.black};
  }
`;

const StyledIconOut = styled(outHeader)`
  display: flex;
  width: 10px;
  height: 5px;
  color: ${({ theme }) => theme.color.red};
`

const StyledIconArrowDown = styled(arrowDown)`
  display: flex;
  width: 26px;
  height: 26px;
  transform: rotate(180deg);
  transition: transform 0.6s;

  .is-active & {
    transform: rotate(0deg);
  }

  ${({ $isActive }) =>
    $isActive && css`
      transform: rotate(0deg);
    `
  }
`;

export { Button, ButtonLockForm, ButtonSmall, StyledIconArrowDown, ButtonIcon, ButtonSmallGreen, ButtonOut, StyledIconOut };
