import { useEffect, FormEvent, ChangeEvent } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import {
  getUser
} from '../../store/user-process/user-process';
import {
  getIsActiveForm,
  resetVoterSendingStatus,
} from '../../store/voting-process/voting-process';
import {
  postVoter,
  fetchStatusForm,
  fetchVotersByDirection,
} from '../../store/api-action';
import Form from '../../components/common/form/form';
import { Input } from '../../components/common/input/input';
import { InputSelect } from '../../components/common/input-select/input-select';
import { Button } from '../../components/common/button/button';
import { UserType, DIRECTIONS } from '../../const';
import { TVoter } from '../../types';
import socket from '../socket/socket';
import { Direction } from '../../utils/utils';

export default function FormAddVoter(): JSX.Element {
  const dispatch = useAppDispatch();
  const isActiveForm = useAppSelector(getIsActiveForm);
  const user = useAppSelector(getUser);
  const sortDirection = Direction.get();

  useEffect(() => {
    socket.on('eventClient', () => {
      setTimeout(() => {
        dispatch(fetchStatusForm());
      }, 200);
    });
  }, [dispatch]);

  const handleFormSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const form = evt.currentTarget;
    if (!form) { return; }
    let formData = new FormData(form) as Iterable<[TVoter]>;
    const data = Object.fromEntries(formData);
    data.direction = user?.type === UserType.Admin ? DIRECTIONS[data.directions] : sortDirection;
    data.isCurrent = true;
    delete data.directions;
    const sendData = dispatch(postVoter(data));
    sendData.then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        form.reset();
        socket.emit('eventChangeDate', 'pro');
        setTimeout(() => {
          dispatch(resetVoterSendingStatus());
        }, 2000);
      }
    })
  };

  const handleChangeDirection = (evt: ChangeEvent<HTMLDivElement>) => {
    const parent = evt.target.closest('div.custom-select');
    if (!parent) {
      return;
    }
    if (!parent.classList.contains('is-open')) {
      const selectDirection = parent.querySelector('.custom-select__text')?.textContent;
      selectDirection && dispatch(fetchVotersByDirection(selectDirection));
    }
  };

  return (
    <Form method="post" action="#" onSubmit={handleFormSubmit}>
    <Input name="family" disabled={!isActiveForm} placeholder="Фамилия" autoComplete="off" required/>
    <Input name="name"disabled={!isActiveForm} placeholder="Имя" autoComplete="off" required/>
    <Input name="surname" disabled={!isActiveForm} placeholder="Отчество" autoComplete="off" required/>
    <Input name="phone" disabled={!isActiveForm} placeholder="Телефон" autoComplete="off" required/>
    <Input name="registration" disabled={!isActiveForm} placeholder="Номер регистрации" autoComplete="off" required/>
    <InputSelect className={`${user?.type === UserType.Regular ? "is-disabled" : ""}`} onClick={handleChangeDirection} data-insert="true" data-required="true" data-select="data-select" data-name="directions" data-id="id-directions"></InputSelect>
    <Input name="address" disabled={!isActiveForm} placeholder="Адрес" autoComplete="off" required/>
    <Input name="job" disabled={!isActiveForm} placeholder="Место работы" autoComplete="off" required/>
    <Button disabled={!isActiveForm} type="submit">Записать в базу</Button>
  </Form>

  );
}
