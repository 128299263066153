import {utils, writeFile} from 'xlsx';
// import { ChangeEvent } from 'react';
// import dayjs from 'dayjs';
// import Dayjs from 'dayjs';
// import duration from 'dayjs/plugin/duration';
// import isBetween from 'dayjs/plugin/isBetween';
// dayjs.extend(duration);
// dayjs.extend(isBetween);


// export const reset = () => {
//   const button = document.querySelector('.control__btn--reset');
//   const selects = document.querySelectorAll('.control__select');
//   const selectsLi = document.querySelectorAll('.custom-select__item');

//   const resetSelects = () => {
//     selects.forEach((select) => {
//       if (select.classList.contains('is-valid') || select.classList.contains('is-valid')) {
//         select.classList.remove('not-empty');
//         select.classList.remove('is-valid');
//       }
//     });

//     selectsLi.forEach((li) => {
//       li.setAttribute('aria-selected','false');
//     });
//   };

//   if (button && selects && selectsLi) {
//     button.addEventListener('click', resetSelects);
//   }

// };

// export const moveHeaderButton = () => {
//   const buttonHeader = document.querySelector('.header__bottom-wrapper-button');
//   const headerWrapper = document.querySelector('.header__bottom-wrapper');
//   const controlDate = document.querySelector('.control__date');

//   const breakpoint = window.matchMedia('(max-width:500px)');
//   const breakpointChecker = () => {
//     if (breakpoint.matches && buttonHeader && controlDate) {
//       buttonHeader.remove();
//       controlDate.after(buttonHeader);
//     } else {
//       if (headerWrapper && buttonHeader) {
//         headerWrapper.append(buttonHeader);
//       }
//     }
//   };

//   breakpoint.addListener(breakpointChecker);

//   if (headerWrapper && buttonHeader) {
//     breakpointChecker();
//   }
// };

// export const getDateBetweenNow = (employees:TEmployees): boolean => dayjs().isBetween(employees.daterestfrom, employees.daterestto);
// export const getDateDiff = (employees:TEmployees) => Number(dayjs(employees.daterestto).diff(employees.daterestfrom, 'day')) + 1;
// export const getDateIsSame = (employees:TEmployees) => dayjs().isSame(employees.daterestto, 'day') || dayjs().isSame(employees.daterestfrom, 'day');
// export const sortByMonth = (employeess:TEmployees[]) => employeess.filter((employees) => dayjs().isSame(employees.daterestfrom, 'month'));
// export const sortByName = (employeesA:TEmployees, employeesB:TEmployees) => employeesA.family.valueOf() > employeesB.family.valueOf() ? 1 : -1;
// export const filterByName = (employeess:TEmployees[], family: string) => employeess.filter((employees) => {
//   if(family === '') {
//     return employeess;
//   }
//   return employees.family === family;
// });

// export const filterByMonth = (employeess:TEmployees[], month: number) => employeess.filter((employees) => {
//   if(month === 0) {
//     return employeess;
//   }
//   const currentMonth = month < 9 ? `0${month}` : month;
//   return dayjs(employees.daterestfrom).isSame(`2023-${currentMonth}-01`, 'month');
// });

export class Token {
  private static _name = 'vote-auth-token';

  static get() {
    const token = localStorage.getItem(this._name);

    return token ?? '';
  }

  static save(token: string) {
    localStorage.setItem(this._name, token);
  }

  static drop() {
    localStorage.removeItem(this._name);
  }
}

export class Direction {
  private static _name = 'vote-direction';

  static get() {
    const direction = localStorage.getItem(this._name);

    return direction ?? '';
  }

  static save(direction: string) {
    localStorage.setItem(this._name, direction);
  }

  static drop() {
    localStorage.removeItem(this._name);
  }
}

export default class TableToExcell {
  private _table: HTMLElement | null = null;
  private _tableName;

  constructor(table: string, tableName: string) {
    this._table = document.getElementById(`${table}`);
    this._tableName = tableName;
    this._writeToFileHandler = this._writeToFileHandler.bind(this);
  }

  init() {
    if (this._table) {
      this._writeToFileHandler();
    }
  }

  _writeToFileHandler() {
    this._writeToFileHundler();
  }

  _writeToFileHundler() {
    const workbook = utils.book_new();
    const sheet = utils.table_to_sheet(this._table);
    utils.book_append_sheet(workbook, sheet, 'Sheet1');
    writeFile(workbook, `${this._tableName}.xlsx`);
  }
}


// export const humanizeDate = (date: string): string => dayjs(date).format('DD.MM.YYYY');
// export const getDateNow = (date?: keyof Dayjs.ConfigType): string => dayjs(date).format('DD.MM.YYYY');
