import { io } from 'socket.io-client'
import { BACKEND_URL } from '../../const';

const socket = io(BACKEND_URL, {
  withCredentials: true,
  extraHeaders: {
    "my-custom-header": "vote"
  }
});

export default socket;
