import * as S from './input.styled';

const Input = ({...props }) => (
  <S.StyledInput {...props} />
);

export {
  Input
};

