import styled from 'styled-components';

const Container = styled.div(props => ({
  width: "100%",
  maxWidth: "1400px",
  margin: "0 auto",
  maxHeight: props.$mh,
  marginTop: props.$mt,
  overflowY: props.$overflow,

  "@media (max-width: 800px)": {
    maxWidth: "100%",
    padding: "0 5px",
  }
}));

export { Container };
