import * as S from './button.styled';

export const Button = ({ children, ...props }) => (
  <S.Button {...props}>{children}</S.Button>
);

export const ButtonLockForm = ({ children, ...props }) => (
  <S.ButtonLockForm {...props}>{children}</S.ButtonLockForm>
);

export const ButtonSmall = ({ children, ...props }) => (
  <S.ButtonSmall {...props}>{children}</S.ButtonSmall>
);

export const ButtonSmallGreen = ({ children, ...props }) => (
  <S.ButtonSmallGreen {...props}>{children}</S.ButtonSmallGreen>
);

export const ButtonIcon = ({...props }) => (
  <S.ButtonIcon {...props}><S.StyledIconArrowDown/></S.ButtonIcon>
);

export const ButtonOut = ({...props }) => (
  <S.ButtonOut {...props}><S.StyledIconOut/></S.ButtonOut>
);
