import styled from 'styled-components';

export const Div = styled.div(props => ({
  margin: props.$margin,
  display: "flex",
  alignItems: "flex-start",
  gap: "10px",
}));

export const ControlBoxTable = styled(Div)`
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ControlBoxButton = styled(Div)`
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: auto;
`;
