import * as S from './footer.styled';
import { currentVotersTheDay, currentVotersTheDayForAdmin, getAllVoters } from '../../../store/voting-process/voting-process';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { getUser } from '../../../store/user-process/user-process';
import { UserType } from '../../../const';

const Footer = () => {
  const allVoters = useAppSelector(getAllVoters);
  const votersForDay = useAppSelector(currentVotersTheDay);
  const votersForAdmin = useAppSelector(currentVotersTheDayForAdmin);
  const user = useAppSelector(getUser);

  return (
    <S.StyledFooter>
      <S.FooterFirstBox>
        <S.FooterText>Новые за сутки: {user.type === UserType.Admin ?  `${votersForAdmin}  |   Всего в базе: ${allVoters}` : votersForDay}</S.FooterText>
      </S.FooterFirstBox>
      <S.FooterSecondBox>
        <S.FooterText>Разработано специально для УТПиСК. 2023 г.</S.FooterText>
      </S.FooterSecondBox>
    </S.StyledFooter>)
};

export default Footer;
