import * as S from './control.styled';

const ControlBox = ({ children, ...props }: any) => (
  <S.Div {...props}>{children}</S.Div>
);

const ControlBoxTable = ({ children, ...props }: any) => (
  <S.ControlBoxTable {...props}>{children}</S.ControlBoxTable>
);

const ControlBoxButton = ({ children, ...props }: any) => (
  <S.ControlBoxButton {...props}>{children}</S.ControlBoxButton>
);

export {ControlBox, ControlBoxTable, ControlBoxButton} ;
