import  { combineReducers } from '@reduxjs/toolkit';
import { userProcess } from './user-process/user-process';
import { votingProcess } from './voting-process/voting-process';

const rootReducer = combineReducers({
  'USER': userProcess.reducer,
  'VOTING': votingProcess.reducer,
});

export default rootReducer;
