import styled from 'styled-components';

const MessageBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 36px;
  transition: background-color 0.3s;
  background-color: ${({ theme }) => theme.color.nepal};

  .is-disabled & {
    background-color: ${({ theme }) => theme.color.red};
  }

  &.is-successfully {
    background-color: ${({ theme }) => theme.color.lightSeaGreen}
  }

  /* @media (max-width: 767px) {
    display: flex;
    justify-content: center;
  } */
`;


export {
  MessageBlock,
};
